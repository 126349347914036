import React from "react";
import "./iceWarp.scss";
import TeamMembers from "./../../components/TeamMembers";
import SongorooComponent from "./../../components/SongorooComponent";
import ContactForm from "./../../components/ContactForm";

import message from "../../assets/chat copy@2x.png";
import message2 from "../../assets/chat@2x.png";
import Christin from "../../assets/christin-hume-311288-unsplash@2x.png";
import icewarpLogo from "../../assets/icechat-icon.svg";
import icewarpIphone from "../../assets/Groupz.png";
import fileExcel from "../../assets/file excel.svg";
import filePowerPoint from "../../assets/file powerpoint.svg";
import fileAcrobat from "../../assets/file acrobat.svg";
import fileKeynote from "../../assets/file keynote.svg";
import fileTable from "../../assets/file table.svg";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('./../../assets/ice-warp-developers', false, /\.(png|jpe?g)$/));
const images2x = importAll(require.context('./../../assets/ice-warp-developers/2x', false, /\.(png|jpe?g)$/));

class IceWarp extends React.Component {
  componentDidMount() {
    const PageWidth = window.innerWidth;
    var DevelopersCount = Math.round(PageWidth / 150);

    if (DevelopersCount > 6) {
      DevelopersCount = 6;
    }

    if (DevelopersCount > 5) {
      document.querySelector('.others-developers').style.display = 'none';
    } else if (DevelopersCount > 5) {
      document.querySelector('.others-developers').style.right = DevelopersCount - 1 + 'rem';
    } else document.querySelector('.others-developers').style.right = '5rem';

    this.setState({
      pageWidth: PageWidth,
    });
    if (DevelopersCount <= 5) {
      this.setState({
        developersCount: 5
      });
    } else {
      this.setState({
        developersCount: DevelopersCount
      });
    }
  }
  
  render() {
    const {Content} = this.props;
    const PageContent = Content.IceWarpPage;
    
    const count = images.length;
    const developers = [
      {
        name: "Dominik",
        job: "Team Leader",
        photo: images[2],
        photo2x: images2x[2]
      },
      {
        name: "Vlastimil",
        job: "Android",
        photo: images[1],
        photo2x: images2x[1]
      },
      {
        name: "Matej",
        job: "iOS",
        photo: images[3],
        photo2x: images2x[3]
      },
      {
        name: "Blažej",
        job: "iOS",
        photo: images[0],
        photo2x: images2x[0]
      },
      {
        name: "Roland",
        job: "iOS",
        photo: images[5],
        photo2x: images2x[5]
      },
      {
        name: "Peter",
        job: "Tester",
        photo: images[4],
        photo2x: images2x[4]
      }
    ];

    return (
    <div className="icewarp-page">
      <div className="icewarp-header-wrapper">
        <div className="icewarp-header-content">
          <div>
            <h1>{PageContent.PostHeader.h1}</h1>
            <p className="text">{PageContent.PostHeader.text}</p>
          </div>
          <img src={message} alt="Chatting girl"/>
          <img src={Christin} alt="message from Icewarp"/>
        </div>
      </div>
      <div className="icewarp-about-wrapper">
        <div className="icewarp-about-content">
          <p className="text-small">{PageContent.AboutText}</p>
          <img src={icewarpLogo} alt="icewarp logo"/>
          <div className="icewarp-dot-pattern"></div>
        </div>
      </div>
      <div className="icewarp-maincontent-wrapper">
        <div className="icewarp-maincontent-content">
          <h2>{PageContent.Articles[0].h2}</h2>
          <p className="text-small">{PageContent.Articles[0].texts[0].text}</p>

          <img id="message" src={message2} alt="Icewarp message"/>
          <div className="icewarp-dotpattern"></div>
          <div className="files">
           <div><img src={fileAcrobat} alt="acrobat file"/></div>
           <div><img src={fileExcel} alt="excel file"/></div>
           <div><img src={fileKeynote} alt="keynote file"/></div>
           <div><img src={fileTable} alt="table file"/></div>
           <div><img src={filePowerPoint} alt="power point file"/></div>
          </div>

          <h2>{PageContent.Articles[1].h2}</h2>
          <p className="text-small">{PageContent.Articles[1].texts[0].text}<br/><br/>
          {PageContent.Articles[1].texts[1].text}</p>

        <TeamMembers developers={developers} count={count} />

        <p className="text-small">{PageContent.Articles[1].texts[2].text}</p>
        </div>
      </div>
      <div className="icewarp-result-wrapper">
        <div className="icewarp-result-content">
          <h2>{PageContent.Articles[2].h2}</h2>
          <p className="text-small">{PageContent.Articles[2].texts[0].text}</p>
          <img src={icewarpIphone} alt="Icewarp opened on iphone"/>
        </div>
      </div>

      <div className="home-page-wrapper">
          <section>
            <SongorooComponent Content={Content.HomePage.SongorooCS}/>
          </section>
      </div>

      <div className="dark-info-wrapper">
              <div className="dark-page-info">
                <h2>{PageContent.FooterInfo.h2}</h2>
                <p className="text-small">{PageContent.FooterInfo.text} <a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
              </div>
            </div>
  
      <div className="contact-form-black-wrapper">
        <div className="contact-form-box">
          <ContactForm Content={Content.ContactPage}/>
        </div>
      </div>
    </div>
    );

  }
  }

  export default IceWarp;