import React from "react";
import "./contactForm.scss";
import { createForm, formShape } from 'rc-form';
import AttachmentIcon from "./../../assets/paperclip 2.svg";
import successIcon from "../../assets/successIcon.png";
 
class Form extends React.Component {
  static propTypes = {
    form: formShape,
  };
 
  submit = e => {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      if(errors==null){
      const formData = new FormData();
      const request = new XMLHttpRequest();
      Object.keys(values).map((key) => formData.append(key, values[key]));
      request.open("POST", "http://strelapp.mofa.sk/sendmails/index.php");
      request.onload = function () {
        if(request.status===200){
          document.querySelector('.contact-form').classList.add('form-fade');
          document.querySelector('.email-success').style.height=document.querySelector('.contact-form').offsetHeight+'px';
          setTimeout(() => {
            document.querySelector('.contact-form').style.display = 'none';
            document.querySelector('.email-success').style.height = '130px';
          }, 2000);
          document.querySelector('.email-success').classList.add('email-success-visible');
        }
        else alert('something wrong');
      };
      this.props.form.resetFields();
      request.send(formData);
      }
    });
  };
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const {Content} = this.props;

    return (
      <div className="contact-form-wrapper">
        <div className="email-success">
          <div>
            <img src={successIcon} alt="success icon"/>
            <p className="text-small">{Content.Success.text1}</p>
          </div>
          
          <p className="text-smallest">{Content.Success.text2}</p>
        </div>
        <form className="contact-form" encType="multipart/form-data"> 
          <div className="flex-top">
            <div className="input-text">
              <input
                name="name"
                className={getFieldError('name') && 'error'}
                type="text"
                placeholder={Content.ContactForm.name}
                {...getFieldProps('name', {
                  rules: [{ required: true }],
                  validateTrigger: null,
                  initialValue: ''
                })}
              />
              <span className="focus-border">
                <i></i>
              </span>
            </div>
            <div className="input-text">
              <input
                name="email"
                className={getFieldError('email') && 'error'}
                type="email"
                placeholder="Email"
                {...getFieldProps('email', {
                  rules: [{ required: true, type: 'email' }],
                  validateTrigger: null,
                  initialValue: ''
                })}
              />
              <span className="focus-border">
                <i></i>
              </span>
          </div>
          </div>
          <div className="input-textarea">
            <textarea
              name="message"
              className={getFieldError('message') && 'error'}
              placeholder={Content.ContactForm.msg}
              {...getFieldProps('message', {
                rules: [{ required: true }],
                validateTrigger: null,
                initialValue: ''
              })}
            />
            <span className="focus-border">
              <i></i>
            </span>
          </div>
          <div className="flex-bottom">
            <label htmlFor="files">
              <img src={AttachmentIcon} alt="add files"/>
              <p>{Content.ContactForm.files}</p>
            </label>
            <input name="file" id="files" type="file" multiple {...getFieldProps('file')}/>
            <button onClick={this.submit}>{Content.ContactForm.btn}</button>
          </div>
        </form>
      </div>
    );
  }
}
 
export default createForm()(Form);