import React from "react";
import { Link } from "react-router-dom";
import "./songorooComponent.scss";

import songorooLogo from "./../../assets/songoroo-logo.svg";
import songImage1 from "./../../assets/kids-see-ghosts-album-art-2018-billboard-1240@2x.png";
import songImage2 from "./../../assets/Aladdin-Sane@2x.png";
import songImage3 from "./../../assets/Pink-Floyd-Dark-Side-Of-The-Moon@2x.png";
import songImage4 from "./../../assets/travis-scott-astroworld-album-art-2018-billboard-embed@2x.png";
import songImage5 from "./../../assets/ariana-grande-sweetner-album-art-2018-billboard-1240@2x.png";
import songImage7 from "./../../assets/kacey-musgraves-golden-hour-album-2018-billboard-embed@2x.png";
import songImage8 from "./../../assets/janelle-monae-dirty-computer-album-2018-billboard-embed@2x.png";
import songImage9 from "./../../assets/lines.svg";



class songorooComponent extends React.Component {
    render(){
        const {Content} = this.props;
        return (
                <div className="content-wrapper from-right">
                      <div className="right-text-holder">
                          <small>{Content.small}</small>
                          <h2>{Content.h2}</h2>
                          <p className="text-small">{Content.text}</p>
                          <Link className="link" to="/songoroo">{Content.link}</Link>
                      </div>
                      <div className="songoroo-img-holder">
                          <div className="songoroo-img-wrapper">
                              <img className="SongAppLogo" src={songorooLogo} alt="Logo of Songoroo"/>
                              <img className="lines" src={songImage9} alt="Lines"/>
                          </div>
                          <div className="songoroo-dots"></div>
                          <div className="song-images-wrapper">
                              <div className="songimg-wrapper1">
                                  <img className="mid-width" src={songImage1} alt=""/>
                                  <img className="mid-width" src={songImage2} alt=""/>
                                  <img className="big-width" src={songImage3} alt=""/>
                              </div>
                              <div className="songimg-wrapper2">
                                  <img className="small-width" src={songImage4} alt=""/>
                                  <img className="mid-width" src={songImage5} alt=""/>
                                  <img className="mid-width2" src={songImage1} alt=""/>
                                  <img className="small-width" src={songImage7} alt=""/>
                                  <img className="mid-width" src={songImage8} alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>
          
        );
    }
}

export default songorooComponent;