import React from "react";
import { Link } from "react-router-dom";
import "./IceWarpComponent.scss";

import IceWarpLogo from "./../../assets/base.svg";
import IceWarpTextImage from "./../../assets/Bitmap@2x.png";
import ChatImage from "./../../assets/chat copy.png";




class IceWarp extends React.Component {
    render() {
        const {Content} = this.props;      
        return (
                <div className="content-wrapper from-left">
                        <div className="right-text-holder">
                            <small>{Content.small}</small>
                            <h2>{Content.h2}</h2>
                            <p className="text-small">{Content.text}</p>
                            <Link className="link" to="/icewarp">{Content.link}</Link>
                        </div>
                        <div className="IceWarp-img-holder">
                            <div className="IceWarp-img-wrapper">
                                <img className="IceWarpLogo" src={IceWarpLogo} alt="Logo of IceWarp"/>
                                <img src={IceWarpTextImage} alt="" className="IceWarpTextImage"/>
                                <img src={ChatImage} alt="" className="chat"/>
                            </div>
                            <div className="IceWarp-dots"></div>
                        </div>
                    </div>
            
        );
    }
}

export default IceWarp;