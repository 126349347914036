import React from "react";
import "./homePage.scss";
import { Link } from "react-router-dom";
import Carousel from "./../../components/Carousel";
import ContactForm from "./../../components/ContactForm";

import image1 from "./../../assets/logo-tatrabanka.svg";
import image2 from "./../../assets/logo-raiffeisen.svg";
import image3 from "./../../assets/logo-mastercard.svg";
import image4 from "./../../assets/logo-heineken.svg";
import image5 from "./../../assets/logo-telekom.svg";
import image6 from "./../../assets/logo-dm.svg";

import surgLogo from "./../../assets/a.svg";
import SongorooComponent from "./../../components/SongorooComponent";
import TechnologyComponent from "../../components/TechnologyComponent";
import DennikNComponent from "./../../components/DennikNComponent";
import IceWarpComponent from "./../../components/IceWarpComponent";

function importAll(r) {
    return r.keys().map(r);
}

const bigIcons = importAll(require.context('./../../assets/mofa-icons/big', false, /\.(png|jpe?g)$/));
const smallIcons = importAll(require.context('./../../assets/mofa-icons/small', false, /\.(png|jpe?g)$/));
const isRetina = window.devicePixelRatio > 1;

class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            icons: isRetina ? bigIcons : smallIcons,
            class:"",
            elements:[],
            randomIcon:this.getRandomInt(18, 31),
            randomNumber:this.getRandomInt(0, 17)
        };
    }

    getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return (number === 5 || number === 6 || number === 15) ? this.getRandomInt(min, max) : number;
    }

    iconAnimation = () => {
        const icons = this.state.icons;
        const temp = icons[this.state.randomNumber];
        icons[this.state.randomNumber] = icons[this.state.randomIcon];
        icons[this.state.randomIcon] = temp;

        this.setState({
            randomNumber: this.getRandomInt(0, 17),
            randomIcon: this.getRandomInt(18, 31),
            class: 'flip-in-ver-left',
            icons
        });
    };

    handleScroll = () => {

            if (this.state.elements[0].getBoundingClientRect().top < 240 && (this.state.elements[0].getBoundingClientRect().top) * -1 < (this.state.elements[0].getBoundingClientRect().height) - 240) {
                document.querySelector('.layout').style.backgroundColor = "black";
            } else if (this.state.elements[1].getBoundingClientRect().top < 240 && (this.state.elements[1].getBoundingClientRect().top) * -1 < (this.state.elements[1].getBoundingClientRect().height) - 240) {
                document.querySelector('.layout').style.backgroundColor = "black";
            } else if (this.state.elements[2].getBoundingClientRect().top < 240 && (this.state.elements[2].getBoundingClientRect().top) * -1 < (this.state.elements[2].getBoundingClientRect().height) - 240) {
                document.querySelector('.layout').style.backgroundColor = "black";
            } else if (this.state.elements[3].getBoundingClientRect().top < 240 && (this.state.elements[3].getBoundingClientRect().top) * -1 < (this.state.elements[3].getBoundingClientRect().height) - 240) {
                document.querySelector('.layout').style.backgroundColor = "black";
            } else if (this.state.elements[4].getBoundingClientRect().top < 240 && (this.state.elements[4].getBoundingClientRect().top) * -1 < (this.state.elements[4].getBoundingClientRect().height) - 240) {
                document.querySelector('.layout').style.backgroundColor = "black";
            } else document.querySelector('.layout').style.backgroundColor = "#fff";
    };


    componentDidMount() {
        var BlackBGElements = document.querySelectorAll(".black-bg");

        this.setState({
            elements: BlackBGElements
        });

        this.interval = setInterval(() => {
            this.iconAnimation();
        }, 7000);
        
        //window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    render() {
        const {Content} = this.props;
        const {Form} = this.props;
        return (
            <div>
                <div className="layout"></div>
                <div className="home-page-wrapper">
                    <div className="content-wrapper-first">
                        <h1>{Content.PostHeader.h1}</h1>
                        <p className="text">{Content.PostHeader.text}</p>
                        <small>{Content.PostHeader.small}</small>
                        <ul>
                            <li><img src={image1} alt="Tatra Banka logo"/></li>
                            <li><img src={image2} alt="Raiffeisen bank logo"/></li>
                            <li><img src={image3} alt="Mastercard logo"/></li>
                            <li><img src={image4} alt="Heineken logo"/></li>
                            <li><img src={image5} alt="Telekom logo"/></li>
                            <li><img src={image6} alt="DM logo"/></li>
                        </ul>
                    </div>
                    <section className="black-bg">
                        <div className="content-wrapper from-right-section">
                            <div className="img-holder team-holder">
                                <div className="img-wrapper"></div>
                                <div className="dots"></div>
                            </div>
                            <div className="text-holder">
                            <small>{Content.Service1.small}</small>
                            <h2>{Content.Service1.h2}</h2>
                            <p className="text-small">{Content.Service1.text}</p>
                            <Link className="link" to="/surglogs">{Content.Service1.link}</Link>
                            </div>
        
                            <div className="text-holder text-holder-right">
                            <small className="small1">{Content.Service2.small}</small>
                            <h2>{Content.Service2.h2}</h2>
                            <p className="text-small">{Content.Service2.text}</p>
                            <Link className="link mar-bot" to="/songoroo">{Content.Service2.link}</Link>
                            </div>
                            <div className="img-holder services-holder">
                                <div className="img-wrapper startup-photo1"></div>
                                <div className="dots"></div>
                            </div>


                            
                            <div className="text-holder">
                            <small className="small2">{Content.Service3.small}</small>
                            <h2>{Content.Service3.h2}</h2>
                            <p className="text-small">{Content.Service3.text}</p>
                            <Link className="link mar-bot" to="/dennikn">{Content.Service3.link}</Link>
                            </div>
                            <div className="img-holder flat-holder">
                                <div className="img-wrapper startup-photo2"></div>
                                <div className="dots"></div>
                            </div>

                        </div>
                    </section>
        
                    <section>
                        <div className="content-wrapper from-left">
                            <div className="img-holder">
                                
                                <div className="img-wrapper surg-logs-photo">
                                    <img className="appLogo" src={surgLogo} alt="Logo of SurgLogs"/>
                                    <div className="iphone-photo"></div>
                                </div>
                                <div className="dots surgLogsDots"></div>
                            </div>
                            <div className="text-holder">
                                <small>{Content.SurgLogsCS.small}</small>
                                <h2>{Content.SurgLogsCS.h2}</h2>
                                <p className="text-small">{Content.SurgLogsCS.text}</p>
                                <Link className="link surg-mar-bot" to="/surglogs">{Content.SurgLogsCS.link}</Link>
                            </div>
                        </div>
                        <SongorooComponent Content={Content.SongorooCS}/>
                        
                    </section>
        
                    <TechnologyComponent Content={Content.Technology}/>
        
        
        
                    <section>
                        <DennikNComponent Content={Content.DennikNCS}/>
                        <IceWarpComponent Content={Content.IceWarpCS}/>
                    </section>
        
                    
        
                    <section className="black-bg">
                        <Carousel Content={Content.Carousel}/>
                        <div className="content-wrapper from-left">
                            <div className="text-holder tech-text">
                                <small>{Content.Icons.small}</small>
                                <h2>{Content.Icons.h2}</h2>
                            </div>
                                <ul className="frameworks-holder clients-holder-width">
                                    {this.state.icons.map((answer, i) => {
                                    if(i<18){
                                        if(i===5 || i===6 || i===15){
                                            return (
                                                <li key={i} className="invisible"></li>
                                            );
                                        }
                                        if (i===this.state.randomNumber){
                                            return (
                                            <li onClick={this.iconAnimation} className="hp-icon" key={i}><img className={this.state.class} src={this.state.icons[this.state.randomIcon]} alt=""/></li>
                                            
                                        );
                                        }
                                        else return (
                                            <li onClick={this.iconAnimation} className="hp-icon" key={i}><img src={answer} alt=""/></li>
                                        );
                                    }
                                    
                                    return (null);
                                    })}
                                </ul>
                            <div className="client-dots not-dots"></div>
                        </div>
                    </section>

                    <div className="dark-info-wrapper black-bg">
                        <div className="dark-page-info">
                            <h2>{Content.FooterInfo.h2}</h2>
                            <p className="text-small">{Content.FooterInfo.text}<a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
                        </div>
                        </div>
            
                    <div className="contact-form-black-wrapper black-bg">
                        <div className="contact-form-box">
                        <ContactForm Content={Form.ContactPage}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;