import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Header from "./components/Header";
import Footer from "./components/Footer";
import CookiesComponent from "./components/CookiesComponent";

import AboutPage from "./pages/AboutPage";
import Careers from "./pages/Careers";
import ContactPage from "./pages/ContactPage";
import DennikN from "./pages/DennikN";
import HomePage from "./pages/HomePage";
import IceWarp from "./pages/IceWarp";
import Songoroo from "./pages/Songoroo";
import SurgLogs from "./pages/SurgLogs";
import Page404 from "./pages/Page404";

const Languages = importAll(require.context('./assets/languages/', false, /\.(json)$/));

function importAll(r) {
  return r.keys().map(r);
}

const history = createBrowserHistory()
history.listen((location, action) => {
  window.scroll(0,0);
});

class App extends React.Component {
  componentDidMount() {
    this.SetUserLanguage();
    this.SetPageLanguage();
  }
  
  constructor(props) {
    super(props);

    this.state = {
      DefaultLanguage:"sk-SK",
      Content: Languages[0],
      Active:false
    };
  }
  ActiveButton = () => {
      if (navigator.language === 'sk-SK') {
        this.setState({
          Active:true
        });
      } else {
        this.setState({
          Active:false
        });
      }
  }

  SetUserLanguage = () => {
      if (navigator.language === 'sk-SK') {
        this.setState({
          DefaultLanguage: navigator.language
        });
      } else {
        this.setState({
          DefaultLanguage: 'en-EN'
        });
      }
  }

  SetPageLanguage = () => {
    if (this.state.DefaultLanguage === 'sk-SK') {
      this.setState({
        Content: Languages[1]
      });
    } else {
      this.setState({
        Content: Languages[0]
      });
    }
  }



  ChangeLanguageEn = () => {
    this.setState({
      Content: Languages[0]
    });

    this.setState({
      Active: true
    });
  };

  ChangeLanguageSk = () => {
    this.setState({
      Content: Languages[1]
    });

    this.setState({
      Active: false
    });
  };

  
  render(){
    return (
        <HashRouter history={history}>
          <div>
          <Header Content={this.state.Content.Header} Form={this.state.Content} active={this.state.Active} ChangeLanguageEn={this.ChangeLanguageEn.bind(this)} ChangeLanguageSk={this.ChangeLanguageSk.bind(this)}/>
            <Switch>
              <Route exact path="/" render={()=><HomePage Content={this.state.Content.HomePage} Form={this.state.Content}/>} />
              <Route exact path="/careers" render={()=><Careers Content={this.state.Content.CareersPage}/>} />
              <Route path="/contact" render={()=><ContactPage Content={this.state.Content}/>}  />
              <Route path="/about" render={()=><AboutPage Content={this.state.Content.AboutPage}/>} />
              <Route path="/dennikn" render={()=><DennikN Content={this.state.Content}/>} />
              <Route path="/icewarp" render={()=><IceWarp Content={this.state.Content}/>} />
              <Route path="/songoroo" render={()=><Songoroo Content={this.state.Content}/>} />
              <Route path="/surglogs" render={()=><SurgLogs Content={this.state.Content}/>} />
              <Route component={Page404} />
            </Switch>
            <CookiesComponent />
            <Footer />
          </div>
        </HashRouter>
    );
  }
}

export default App;