import React from "react";
import "./DennikNComponent.scss";
import { Link } from "react-router-dom";
import dennikNLogo from "./../../assets/logo.svg";

import dennikNPhone from "./../../assets/hp-cs-dennik.png";
import dennikNPhone2x from "./../../assets/hp-cs-dennikN@2x.png";



class DennikNComponent extends React.Component {
  render() {
    const isRetina = window.devicePixelRatio > 1;
    const {Content} = this.props;
    return (
      <div className="content-wrapper from-right">
          <div className="img-holder">
              
              <div className="img-wrapper dennikN-photo">
                  <img className="appLogo" src={dennikNLogo} alt="Logo of SurgLogs"/>
                  <img src={isRetina ? dennikNPhone2x : dennikNPhone} className="dennikN-iphone-photo" alt="" />
              </div>
              <div className="dots-dennikn"></div>
          </div>
          <div className="text-holder">
              <small>{Content.small}</small>
              <h2>{Content.h2}</h2>
              <p className="text-small">{Content.text}</p>
              <Link className="link surg-mar-bot" to="/dennikn">{Content.link}</Link>
          </div>
      </div>
          
    );
  }
}

export default DennikNComponent;