import React from "react";
import { Link } from "react-router-dom";
import MofaLogo from "./../../assets/mofa-logo-red.svg";
import "./header.scss";
import Iphone from "./../../assets/iPhoneX09SpaceGreyRight@2x.png";
import covers from "./../../assets/covers@2x.png";
import base from "./../../assets/base.svg";
import bitmap from "./../../assets/Bitmap@2x.png";
import dennikN from "./../../assets/dennik@2x.png";
import dennikNLogo from "./../../assets/logo.svg";

class Header extends React.Component {
  render() {
    const {Content} = this.props;
     return (
    <header>
          <Link className="logo" to="/" onClick={changeCheckBox}>
            <img src={MofaLogo} alt="Logo Mofa"/>
          </Link>
          <Link className="contact" to="/contact" onClick={changeCheckBox}>{Content.texts[0]}</Link>
      <nav role='navigation'>
        <div id="menuToggle">
          <input onClick={headerfix}  id="checkBox" type="checkbox" />
          <span></span>
          <span></span>
          <ul id="menu">
            <li>
              <Link id="none" to="#">{Content.texts[1]}</Link>
              <div className="projects">
                <Link to="/surglogs" onClick={changeCheckBox} className="project surglogs">
                  <img src={Iphone} alt="iphone x with surglogs app"/>
                  <p>Digitalizing the Healthcare Facility</p>
                </Link>
                <Link to="/songoroo" onClick={changeCheckBox} className="project b2b">
                  <img src={covers} alt="photos from videoclips"/>
                  <p>Songoroo</p>
                </Link>
                <Link to="/icewarp" onClick={changeCheckBox} className="project icewrap">
                  <img id="icewrap-first" src={base} alt="icewrap logo"/>
                  <img src={bitmap} id="icewrap-secound" alt="Icewrap chat"/>
                  <p>Collaboration hub</p>
                </Link>
                <Link to="/dennikn" onClick={changeCheckBox} className="project DennikN">
                  <img src={dennikN} alt="Mobile with application"/>
                  <img src={dennikNLogo} alt="Logo of Dennik N"/>
                </Link>
              </div>
            </li>
            <li><Link onClick={changeCheckBox} to="/about">{Content.texts[2]}</Link></li>
            <li><Link to="/careers" onClick={changeCheckBox}>{Content.texts[3]}</Link></li>
            <li><Link className={"lang-bttn" + (this.props.active ? ' lang-active' : '')} to="#" onClick={this.props.ChangeLanguageEn}>EN</Link><Link className={"lang-bttn" + (this.props.active ? '' : ' lang-active')} to="#" onClick={this.props.ChangeLanguageSk}>SK</Link></li>
          </ul>
          <div onClick={changeCheckBox} id="overlay">
          </div>
        </div>
      </nav>
    </header>
  );
  }
}


//JS 
function changeCheckBox(){
  document.getElementById('checkBox').checked=false;
  headerfix();
}
function headerfix(){
  if(document.getElementById('checkBox').checked===true)
  document.querySelector('header').classList.add("header-fix");
  else document.querySelector('header').classList.remove("header-fix");
}
export default Header;