import React from "react";
import "./cookiesComponent.scss";

import CookieConsent from "react-cookie-consent";
 
class CookiesComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            changeWindow: false,
            hideLessCookies: false,
            acceptFade:false
        }
      }

      changeClass() {
        this.setState({changeWindow:!this.state.changeWindow,cover:!this.state.cover});
        this.setState({hideLessCookies:!this.state.hideLessCookies});
      }
    

    render(){

        let moreCookies = ["cookies-content"];
        let lessCookies = ["less-cookies"];
        let cover = ["cookies-cover "];
        let wrapper = ["cookies-wrapper"];
      if(this.state.changeWindow) {
        moreCookies.push('show-more-cookies');
        cover.push('show-cookies-cover')
      }
      if(!this.state.changeWindow) {
        moreCookies.push('hide-more-cookies');
        cover.push('hide-cookies-cover')
        lessCookies.push('show-more-cookies')
      }

      if(this.state.hideLessCookies){
          lessCookies.push('hide-less-cookies')
      }

        return (
            
                
                    <CookieConsent
                        buttonText="Accept"
                        cookieName="myAwesomeCookieName2"
                        expires={250}
                        disableStyles={true}
                        acceptOnScroll={true}
                        acceptOnScrollPercentage={10}
                        containerClasses={wrapper.join(' ')}
                    >
                        <div className={cover.join(' ')}></div>
                        <div className="cookie-modal-window">
                        
                        
                     <div className={moreCookies.join(' ')}>   
                            <div className="more-cookies">
                                    <div>
                                        <h5>Essential cookies</h5>
                                        <p>These cookies are strictly necessary to provide you with services available and to use some of its features, for example access to secure areas. Because these cookies are strictly necessary to deliver our services safely, you cannot refuse them without impacting how our website functions. You can block or delete them by changing your browser settings.</p>
                                    </div>
                                    <div>
                                        <h5>Marketing personalisation/retargeting cookies</h5>
                                        <p>These cookies are used to make advertising messages more relevant to you and your interests. They also perform functions like preventing the same ad from continuously reappearing, ensuring that ads display properly, and in some cases selecting advertisements based on your interests.</p>
                                    </div>
                                    <div>
                                        <h5>Marketing analytics cookies</h5> 
                                        <p className="cookies-last-p">These cookies collect information that is used either in aggregate form to help us understand how visitors use our website or how effective our marketing campaigns are. They help us to customize our sites and the signup to enhance your experience.</p>
                                    </div>
                                </div>
                            
                        </div>
                        <div className={lessCookies.join(' ')}>
                             <p >
                             We use cookies to provide you with the best browsing experience. The data collected by cookies is used to optimise the website for our visitors and deliver targeted information.
                             </p>
                        </div>
                        <div className="cookies-settings-bg">
                         <p className="more-cookies-text" onClick={this.changeClass.bind(this)}>{!this.state.changeWindow ? "Cookie settings" : "Hide cookie settings"}</p>
                        </div>
                        </div>
                    
                    </CookieConsent> 
                 
        );
    }
}
    
export default CookiesComponent;