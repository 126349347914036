import React from "react";
import "./contactPage.scss";
import ContactForm from "./../../components/ContactForm";
import PropTypes from "prop-types";

class ContactPage extends React.Component {
    
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    };

    componentDidMount = () => {
      ViewPortChange();
    };

    render() {
      const {Content} = this.props;
      return (
        <div className="contact-page">
            <div className="contact-page-info">
              <h2>{Content.ContactPage.Texts.h1}</h2>
              <p className="text-small">{Content.ContactPage.Texts.text} <a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
            </div>

          <ContactForm Content={Content.ContactPage} />
        </div>
      );
    }
  }

  function ViewPortChange(){
    if(document.querySelector('body').offsetHeight < window.innerHeight){
      var footerHeight = document.querySelector('.footer').offsetHeight;
      var padding = 112 / window.innerHeight * 100;
      var height = 100 - padding - (footerHeight / window.innerHeight) * 100;
      
      document.querySelector('.contact-page').style.height = height + 'vh';
    }
    else{
      document.querySelector('.contact-page').style.height = 'auto';
    }
  };

  export default ContactPage;