import React from "react";
import "./teamMembers.scss";

class TeamMembers extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
          ElWidth:'',
          DevelopersCount:0
        };
    }
  componentDidMount() {
    const PageWidth = window.innerWidth;
    var DevelopersCount = Math.round(PageWidth/150);

    this.setState({
      pageWidth: PageWidth,
    });
    if (DevelopersCount<=5) {
      this.setState({
        developersCount: 5
      });
    }
    else{
      this.setState({
        developersCount: DevelopersCount
      });
    }
  }
  render() {
    const {developers} = this.props;
    const isRetina = window.devicePixelRatio > 1;
    
    return (
      <div className="project-developers">
          {developers.map((answer, i) => {
            if(i<this.state.developersCount-1 && this.state.developersCount>5){
                return (<div key={i} className="developer">
                  <img src={isRetina ? answer.photo2x : answer.photo} alt={answer.name +' '+ answer.job} />
                    {answer.name}
                    <p>{answer.job}</p>
                </div>);
              }
            else if(i<5){
            return (<div key={i} className="developer">
                  <img src={isRetina ? answer.photo2x : answer.photo} alt={answer.name +' '+ answer.job} />
                    {answer.name}
                    <p>{answer.job}</p>
                </div>);
            }
            else return null;
        })}
        <div className="others-developers">
          <h2>+{this.props.count-this.state.developersCount}</h2>
          <p>teammembers</p>
        </div>
      </div>
    );
  }
}
 
export default TeamMembers;