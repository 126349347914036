import React from "react";
import "./aboutPage.scss";

import Play from "./../../assets/ico-play.svg";
import screen from "./../../assets/Screenshot 2019-04-11 at 23.10.04@2x.png";

import ContentPhoto1 from "./../../assets/mofa08@1x.png";
import ContentPhoto2 from "./../../assets/mofa04@1x.png";
import ContentPhoto3 from "./../../assets/mofaheads03@1x.png";

import PostContentPhoto1 from "./../../assets/mofaheads01@1x.png";
import PostContentPhoto2 from "./../../assets/mofa05@1x.png";
import PostContentPhoto3 from "./../../assets/mofaheads31 copy@1x.png";

class AboutPage extends React.Component {
    render(){
      const {Content} = this.props;

      function playVid() {
        document.querySelector('.playpause').style.display= "none";
        document.getElementById('video').setAttribute("controls","");
        document.getElementById('video').removeAttribute("poster");
      }
      function pauseVid() {
        document.querySelector('.playpause').style.display= "block";
        document.getElementById('video').removeAttribute("controls");
        document.getElementById('video').setAttribute("controls",screen);
      }
      function play() {
        document.getElementById('video').play();
      }
      
      return (
        <div className="about-page">
          <div className="about-page-post-header-wrap">
            <div className="about-page-post-header">
              <h1>{Content.PostHeader.h1}</h1>
              <p className="text">{Content.PostHeader.text}</p>
            </div>
          </div>
  
            <div className="about-page-video">
              <video onPlay={playVid} onPause={pauseVid} id="video" preload="none" poster={screen}>
                  <source src="http://strelapp.mofa.sk/mastersofapps.mov"
                          type="video/webm"/>
                  <source src="http://strelapp.mofa.sk/mastersofapps.mov" type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
              </video>
              <div onClick={play} className="playpause"><img src={Play} alt=""/></div>
            </div>
  
            <div className="about-page-content">
              <p className="text-small text-animated">{Content.Texts[0].text}</p>
              <div className="about-page-content-photos">
                <img src={ContentPhoto1} alt="Guys in office"/>
                <img src={ContentPhoto2} alt="Guys on breakfast"/>
                <img src={ContentPhoto3} alt="Boss playing"/>
              </div>
              <p className="text-small about-page-text2 text-animated">{Content.Texts[1].text}</p>
              <p className="text-small about-page-text3 text-animated">{Content.Texts[2].text}</p>
            </div>
  
            <div className="about-page-post-content-wrap">
              <div className="about-page-post-content">
                <p className="text">{Content.Texts[3].text}</p>
                  <div className="about-page-post-content-photos">
                    <img src={PostContentPhoto1} alt="Boss with pan"/>
                    <img src={PostContentPhoto2} alt="Guys playing fifa"/>
                    <img src={PostContentPhoto3} alt="Team member"/>
                  </div>
              </div>
          </div>

          <div className="dark-info-wrapper">
            <div className="dark-page-info">
              <h2>{Content.FooterInfo.h2}</h2>
              <p className="text-small">{Content.FooterInfo.text}<a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
            </div>
          </div>
        </div>
        );
    }
  }

  export default AboutPage;