import React from "react";
import "./page404.scss";
import { Link } from "react-router-dom";
import ScrollReveal from 'scrollreveal';

class Page404 extends React.Component {
    componentDidMount() {
        ScrollReveal().reveal('.page-404-content', {
            duration: 1000,
            delay: 1000,
        });
    }
    
    render() {
        return (
            <div className="page-404">
                <div className="page-404-content slide-in-elliptic-top-fwd">
                    <h1>Oops, you’ve got lost in space.</h1>
                    <p className="text-small">The page you requested could not be found. But we got you covered.</p>
                    <Link className="link" to="/">Head back to MOFA</Link>
                </div>
                <div className="space-bg"></div>
            </div>
        )
    }   
  }

  export default Page404;