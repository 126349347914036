import React, { Component } from 'react';

import './styles.css';

export default class Canvas extends Component {
    componentDidMount() {
        const script = document.createElement("script");

        script.src = "./curve/curve.js";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <canvas id="canvas-background" className="canvas" width="100%" height="150" />
        )
    }
}
