import React from "react";
import "./songoroo.scss";
import ContactForm from "./../../components/ContactForm";
import TeamMembers from "./../../components/TeamMembers";
import DennikNComponent from "./../../components/DennikNComponent";
import Canvas from "./../../components/Canvas";

import SongorooFillImg1 from "./../../assets/img-songoroo.png";
import SongorooImg1 from "./../../assets/songoroo-2@2x.png";
import SongorooImg2 from "./../../assets/songoroo-3@2x.png";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('./../../assets/songoroo-developers', false, /\.(png|jpe?g)$/));
const images2x = importAll(require.context('./../../assets/songoroo-developers/2x', false, /\.(png|jpe?g)$/));

class Songoroo extends React.Component {
  componentDidMount() {
    const PageWidth = window.innerWidth;
    var DevelopersCount = Math.round(PageWidth / 150);

    if (DevelopersCount > 7) {
      DevelopersCount = 7;
    }

    if (DevelopersCount === 7) {
      document.querySelector('.others-developers').style.display = 'none';
    } else if (DevelopersCount > 5) {
      document.querySelector('.others-developers').style.right = DevelopersCount - 1 + 'rem';
    } else document.querySelector('.others-developers').style.right = '5rem';

    this.setState({
      pageWidth: PageWidth,
    });
    if (DevelopersCount <= 5) {
      this.setState({
        developersCount: 5
      });
    } else {
      this.setState({
        developersCount: DevelopersCount
      });
    }
  }

  render() {
      const {Content} = this.props;
      const PageContent = Content.SongorooPage;

      const count = images.length;
      const developers = [
        {
          name: "Daniel",
          job: "Projekt lead",
          photo: images[1],
          photo2x: images2x[1]
        },
        {
          name: "Janko",
          job: "Tech Lead",
          photo: images[3],
          photo2x: images2x[3]
        },
        {
          name: "Zara",
          job: "Android",
          photo: images[0],
          photo2x: images2x[0]
        },
        {
          name: "Jakub",
          job: "Designer",
          photo: images[2],
          photo2x: images2x[2]
        },
        {
          name: "Hrayr",
          job: "iOS",
          photo: images[4],
          photo2x: images2x[4]
        },
        {
          name: "Kamil",
          job: "System architekt",
          photo: images[6],
          photo2x: images2x[6]
        },
        {
          name: "Vahe",
          job: "Python",
          photo: images[5],
          photo2x: images2x[5]
        }
      ];

      return (
        <div className="songoroo-page">
  
          <div className="songoroo-page-header-wrapper">
            <div className="songoroo-page-header">
                <h1>{PageContent.PostHeader.h1}</h1>
                <p className="text">{PageContent.PostHeader.text}</p>
            </div>
          </div>
  
          <div className="songoroo-page-post-header">
            <p className="text-small">{PageContent.AboutText}</p>
            <div></div>
          </div>
  
          <div className="songoroo-page-text-wrapper">
            <section className="songoroo-page-text">
              <h2>{PageContent.Articles[0].h2}</h2>
              <p className="text-small">{PageContent.Articles[0].texts[0].text}</p>
              <p className="text-small second-text">{PageContent.Articles[0].texts[1].text}</p>
            </section>
          </div>
  
          <div className="songoroo-fill-image">
            <Canvas/>
            <img src={SongorooFillImg1} alt="songoroo application"/>
          </div>
  
          <div className="songoroo-page-text-wrapper">
            <section className="songoroo-page-text">
              <h2>{PageContent.Articles[1].h2}</h2>
              <p className="text-small">{PageContent.Articles[1].texts[0].text}</p>
              <p className="text-small second-text">{PageContent.Articles[1].texts[1].text}</p>
            </section>
          </div>
  
          <div className="songoroo-page-img-fullsize from-right-section">
            <img src={SongorooImg1} alt="songoroo application"/>
          </div>
  
          <div className="songoroo-page-text-wrapper">
            <section className="songoroo-page-text">
              <p className="text-small">{PageContent.Articles[1].texts[2].text}</p>
              <p className="text-small second-text">{PageContent.Articles[1].texts[3].text}</p>
            </section>
          </div>
  
          <div className="songoroo-page-img">
            <img src={SongorooImg2} alt="songoroo application"/>
          </div>
        
          <div className="songoroo-page-text-wrapper">
            <section className="songoroo-page-text">
              <h2>{PageContent.Articles[2].h2}</h2>
              <p className="text-small">{PageContent.Articles[2].texts[0].text}</p>
              <p className="text-small second-text">{PageContent.Articles[2].texts[1].text}</p>
            </section>
          </div>
  
          <div className="songoroo-page-post-content">
            <div className="songoroo-page-post-content-flex">
            <div className="post-content-text-wrapper">
              <p className="text-small">{PageContent.Articles[2].texts[2].text}</p>
            </div>
              <div className="white-dot-pattern"></div>
            </div>
          </div>

          <div className="songoroo-team-members-wrapper">
            <div className="songoroo-team-members-content">
              <TeamMembers developers={developers} count={count} />
            </div>
          </div>
  
          

            <div className="home-page-wrapper">
            <section>
              <DennikNComponent Content={Content.HomePage.DennikNCS}/>
            </section>
          </div>
          

          <div className="dark-info-wrapper">
              <div className="dark-page-info">
                <h2>{PageContent.FooterInfo.h2}</h2>
                <p className="text-small">{PageContent.FooterInfo.text} <a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
              </div>
            </div>
  
          <div className="contact-form-black-wrapper">
            <div className="contact-form-box">
              <ContactForm Content={Content.ContactPage}/>
            </div>
          </div>
  
        </div>
      );
    }
  }

  export default Songoroo;