import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import Logo from "../../assets/mofa-logo.svg";
import Pin from "../../assets/pin.svg";

import PropTypes from "prop-types";
import { withRouter } from "react-router";

class Footer extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    function getClassName (location){
      if(location === '/'){
        return "footer footer-dark black-bg";
      }
      else {
        return "footer footer-dark";
      }
    }

  return (
    <div className={getClassName(this.props.location.pathname)}>
        <div className="footer-map">
          <div className="footer-gradient-bg-top"></div>
          <div className="footer-gradient-bg-bottom"></div>
          <div className="prague-office">
            <img src={Pin} alt=""/>
            <p className="text-small">Prague Office</p>
            <small>Thamova 18</small>
            <small>Prague, Czech Republic</small>
          </div>
          <div className="bratislava-office">
            <img src={Pin} alt=""/>
            <p className="text-small">Bratislava Office</p>
            <small>Pražská 11</small>
            <small>Bratislava, Slovakia</small>
          </div>
        </div>
        <div className="logo">
            <Link to="/"><img src={Logo} alt="Mofa Logo"/></Link>
        </div>
        <ul>
          <li>
            <Link to="/about">About us</Link>
          </li>
          <li>
            <Link to="/careers">Carrers</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
    </div>
  );
}
}
export default withRouter(Footer);