import React from "react";
import "./dennikN.scss";
import ContactForm from "./../../components/ContactForm";
import IceWarpComponent from "./../../components/IceWarpComponent";

import EditorImg from "./../../assets/Fill 1.svg";
import DenniknImg1 from "./../../assets/Group 2@2x.png";
import DenniknImg2 from "./../../assets/Group 3@2x.png";

class DennikN extends React.Component {
    componentDidMount() {
      
    }
    
    render(){
      const {Content} = this.props;
      const PageContent = Content.DennikNPage;
      return (
        <div className="dennikn-page">
  
          <div className="dennikn-page-header-wrapper">
            <div className="dennikn-page-header">
                <h1>{PageContent.PostHeader.h1}</h1>
                <p className="text">{PageContent.PostHeader.text}</p>
            </div>
          </div>
  
          <div className="dennikn-page-post-header">
            <p className="text-small">{PageContent.AboutText}</p>
            <div></div>
          </div>
  
          <div className="dennikn-page-text-wrapper">
            <section className="dennikn-page-text">
              <h2>{PageContent.Articles[0].h2}</h2>
              <p className="text-small">{PageContent.Articles[0].texts[0].text}</p>
            </section>
          </div>
  
          <div className="dennikn-page-editor-wrapper">
            <section className="dennikn-page-editor">
              <div className="editor-text-wrapper">
                <p className="text">{PageContent.Reference.texts[0].text}</p>
                <p className="text-small">{PageContent.Reference.texts[1].text}</p>
                <p className="text-small">{PageContent.Reference.texts[2].text}<a className="link" href="mailto:aplikacia@dennikn.sk">aplikacia@dennikn.sk</a></p>
                <p>Matúš Kostolný</p>
                <small>Editor in Cief, Dennik N</small>
                <img src={EditorImg} alt="icon"/>
              </div>
            </section>
          </div>
  
          <div className="dennikn-page-text-wrapper">
            <section className="dennikn-page-text">
              <h2>{PageContent.Articles[1].h2}</h2>
              <p className="text-small">{PageContent.Articles[1].texts[0].text}</p>
              <p className="text-small second-text">{PageContent.Articles[1].texts[1].text}</p>
            </section>
          </div>
  
          <div className="dennikn-page-img from-right-section">
            <img src={DenniknImg1} alt="DennikN application"/>
          </div>
  
          <div className="dennikn-page-text-wrapper padding-bot-small">
            <section className="dennikn-page-text">
              <h2>{PageContent.Articles[2].h2}</h2>
              <p className="text-small">{PageContent.Articles[2].texts[0].text}</p>
            </section>
          </div>

          <div className="dennikn-page-text-wrapper padding-top-small">
            <section className="dennikn-page-text">
              <p className="text-small second-text">{PageContent.Articles[2].texts[1].text}</p>
              <p className="text-small third-text">{PageContent.Articles[2].texts[2].text}</p>
            </section>
          </div>
  
          <div className="dennikn-page-img-2">
            <img src={DenniknImg2} alt="DennikN application"/>
          </div>

          <div className="dennikn-page-post-content">
            <div className="dennikn-page-post-content-flex">
            <div className="post-content-text-wrapper">
              <h2>{PageContent.Articles[3].h2}</h2>
              <p className="text-small">{PageContent.Articles[3].texts[0].text}</p>
              <p className="text-small">{PageContent.Articles[3].texts[1].text}</p>
            </div>
              <div className="dot-pattern"></div>
            </div>
          </div>
          
          <div className="home-page-wrapper">
            <section>
              < IceWarpComponent Content={Content.HomePage.IceWarpCS} />
            </section>
          </div>
          
          <div className="dark-info-wrapper">
              <div className="dark-page-info">
                <h2>{PageContent.FooterInfo.h2}</h2>
                <p className="text-small">{PageContent.FooterInfo.text} <a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
              </div>
            </div>
  
          <div className="contact-form-black-wrapper">
            <div className="contact-form-box">
              <ContactForm Content={Content.ContactPage}/>
            </div>
          </div>
  
        </div>
      );
    }
  }

  export default DennikN;