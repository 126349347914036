import React from "react";
import "./TechnologyComponent.scss";

import Image1 from "./../../assets/Android_Robot.svg";
import Image2 from "./../../assets/icons8-ios-logo.svg";
import Image3 from "./../../assets/Unofficial_JavaScript_logo_2.svg";
import Image4 from "./../../assets/React-icon.svg";
import Image5 from "./../../assets/angular_solidBlack.svg";
import Image6 from "./../../assets/Node.js_logo.svg";
import Image7 from "./../../assets/PHP-logo.svg";
import Image8 from "./../../assets/Python-logo-notext.svg";


class TechnologyComponent extends React.Component {
  render() {
    const {Content} = this.props;
    return (
      <section className="black-bg">
          <div className="content-wrapper from-left">
              <div className="text-holder tech-text">
              <small className="technologySmall">{Content.small}</small>
              <h2>{Content.h2}</h2>
              <p className="text-small">{Content.text}</p>
              </div>
              <ul className="frameworks-holder">
                  <li><img src={Image1} alt=""/></li>
                  <li><img src={Image2} alt=""/></li>
                  <li className="invisible"><img src="" alt=""/></li>
                  <li className="invisible"><img src="" alt=""/></li>
                  <li className="invisible"><img src="" alt=""/></li>
                  <li><img src={Image3} alt=""/></li>
                  <li><img src={Image4} alt=""/></li>
                  <li><img src={Image5} alt=""/></li>
                  <li><img src={Image6} alt=""/></li>
                  <li><img src={Image7} alt=""/></li>
                  <li><img src={Image8} alt=""/></li>
                  <li className="invisible"><img src="" alt=""/></li>
                </ul>
                <div className="dots not-dots"></div>
           </div>
      </section>
    );
  }
}

export default TechnologyComponent;