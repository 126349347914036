import React from "react";
import "./surgLogs.scss";
import DennikNComponent from "./../../components/DennikNComponent";
import TeamMembers from "./../../components/TeamMembers";
import ContactForm from "./../../components/ContactForm";

import surglogsIphones from "./../../assets/s2@2x-min.png";
import surglogsIphone from "./../../assets/s3@2x.png";
import surglogsLaptop from "./../../assets/s4@2x.png";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('./../../assets/surglogs-developers', false, /\.(png|jpe?g)$/));
const images2x = importAll(require.context('./../../assets/surglogs-developers/2x', false, /\.(png|jpe?g)$/));

class SurgLogs extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
          ElWidth:'',
          DevelopersCount:0
        };
    }
    
  componentDidMount() {
    const PageWidth = window.innerWidth;
    var DevelopersCount = Math.round(PageWidth / 150);
    if (DevelopersCount > 12) {
      DevelopersCount = 12;
    }

    if (DevelopersCount === 12) {
      document.querySelector('.others-developers').style.display = 'none';
    } else if (DevelopersCount > 5) {
      document.querySelector('.others-developers').style.right = DevelopersCount - 1 + 'rem';
    } else document.querySelector('.others-developers').style.right = '5rem';

    this.setState({
      pageWidth: PageWidth,
    });
    if (DevelopersCount <= 5) {
      this.setState({
        developersCount: 5
      });
    } else {
      this.setState({
        developersCount: DevelopersCount
      });
    }
  }
  
  render(){
    const {Content} = this.props;
    const PageContent = Content.SurgLogsPage;

    const count = images.length;
      const developers = [
        {
          name: "Jakub",
          job: "CTO",
          photo: images[4],
          photo2x: images2x[4]
        },
        {
          name: "Ondrej",
          job: "CIO",
          photo: images[10],
          photo2x: images2x[10]
        },
        {
          name: "Jiri",
          job: "FrontEnd",
          photo: images[5],
          photo2x: images2x[5]
        },
        {
          name: "Michal",
          job: "UX/UI designer",
          photo: images[9],
          photo2x: images2x[9]
        },
        {
          name: "Andrej",
          job: "QA tester",
          photo: images[0],
          photo2x: images2x[0]
        },
        {
          name: "David",
          job: "FrontEnd",
          photo: images[2],
          photo2x: images2x[2]
        },
        {
          name: "Erik",
          job: "FrontEnd",
          photo: images[3],
          photo2x: images2x[3]
        },
        {
          name: "Marek",
          job: "iOS",
          photo: images[6],
          photo2x: images2x[6]
        },
        {
          name: "Martin",
          job: "Android",
          photo: images[7],
          photo2x: images2x[7]
        },
        {
          name: "Michal",
          job: "Python",
          photo: images[8],
          photo2x: images2x[8]
        }, 
        {
          name: "Peter",
          job: "QA tester",
          photo: images[11],
          photo2x: images2x[11]
        }
      ];

    return (
    <div className="surglogs-page">
      <div className="surglogs-welcome-wrapper">
        <div className="surglogs-welcome-content">
          <h1>{PageContent.PostHeader.h1}</h1>
          <p className="text">{PageContent.PostHeader.text}</p>
        </div>
      </div>
      <div className="surglogs-start-wrapper">
          <p className="text-small">{PageContent.AboutTexts[0].text}</p>
          <p className="text-small">{PageContent.AboutTexts[1].text}</p>
          <div className="surglogs-dotpattern"></div>
          </div>

          <div className="surglogs-challange-wrapper">
            <div className="surglogs-challange-content">
              <h2>{PageContent.Articles[0].h2}</h2>
              <p className="text-small">{PageContent.Articles[0].texts[0].text}</p><br/>
              <p className="text-small">{PageContent.Articles[0].texts[1].text}</p>
            </div>
              <img className="surglogs-iphones-image" src={surglogsIphones} alt="Surglogs app open on mobile"/>
            <div className="surglogs-challange-content">
              <h2>{PageContent.Articles[1].h2}</h2>
              <p className="text-small">{PageContent.Articles[1].texts[0].text}</p><br/>
              <p className="text-small">{PageContent.Articles[1].texts[1].text}</p>
              <img className="surglogs-smaller-img" src={surglogsIphone} alt="Surglogs app open on mobile"/>
              <p className="text-small">{PageContent.Articles[1].texts[2].text}</p><br/>
              <p className="text-small">{PageContent.Articles[1].texts[3].text}</p><br/>
            </div>
            <div className="surglogs-teammembers">
              <TeamMembers developers={developers} count={count} />
            </div>
            <div className="surglogs-challange-content">   
              <p className="text-small">{PageContent.Articles[1].texts[4].text}</p><br/>
              <p className="text-small">{PageContent.Articles[1].texts[5].text}</p>
              <img className="surglogs-smaller-img" src={surglogsLaptop} alt="Surglogs app open on laptop"/>
            </div>
      </div>

      <div className="surglogs-result-wrapper">
          <div className="surglogs result-wrapper">
          <h2>{PageContent.Articles[2].h2}</h2>
          <p className="text-small">{PageContent.Articles[2].texts[0].text}</p>
          div
          <div className="surglogs-dotpattern"></div>
        </div>
      </div>
      
      <div className="home-page-wrapper">
        <section>
          <DennikNComponent Content={Content.HomePage.DennikNCS}/> 
        </section>
      </div>

      <div className="dark-info-wrapper">
              <div className="dark-page-info">
                <h2>{PageContent.FooterInfo.h2}</h2>
                <p className="text-small">{PageContent.FooterInfo.text} <a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
              </div>
            </div>
  
      <div className="contact-form-black-wrapper">
        <div className="contact-form-box">
          <ContactForm Content={Content.ContactPage}/>
        </div>
      </div>

    </div>
    );
  }
  }

  export default SurgLogs;