import React from "react";
import "./careers.scss";
import { Link } from "react-router-dom";

import PostContentPhoto1 from "./../../assets/mofaheads01@1x.png";
import PostContentPhoto2 from "./../../assets/mofa05@1x.png";
import PostContentPhoto3 from "./../../assets/mofaheads31 copy@1x.png";
import Dots from "./../../assets/dots.svg";

class Careers extends React.Component {

  render() {
    const {Content} = this.props;
        return (
          <div className="careers-page">
    
            <div className="careers-page-post-header-wrap">
              <div className="careers-page-post-header">
                <h1>{Content.PostHeader.h1}</h1>
              </div>
            </div>
    
              <div className="careers-page-content">
                <section>
                  <h2>{Content.Developers[0].h2}</h2>
                  <p className="text-small">{Content.Developers[0].text}</p>
                  <Link className="link" to="/contact">{Content.Developers[0].link}</Link>
                </section>
              </div>
              <div className="careers-dots">
                <img src={Dots} alt="dots pattern"/>
              </div>
              
              <div className="careers-page-texts">
                <p className="text-small">{Content.Texts[0].text}</p>
                <p className="text-small">{Content.Texts[1].text}</p>
              </div>
    
              <div className="careers-page-post-content-wrap">
                <div className="careers-page-post-content">
                  <p className="text">{Content.Texts[2].text}</p>
                    <div className="careers-page-post-content-photos">
                      <img src={PostContentPhoto1} alt="Boss with pan"/>
                      <img src={PostContentPhoto2} alt="Guys playing fifa"/>
                      <img src={PostContentPhoto3} alt="Team member"/>
                    </div>
                  <div/>
                </div>
              </div>
    
              <div className="dark-info-wrapper">
                <div className="dark-page-info">
                  <h2>{Content.FooterInfo.h2}</h2>
                  <p className="text-small">{Content.FooterInfo.text} <a className="link" href="mailto:hello@mofa.sk">hello@mofa.sk</a></p>
                </div>
              </div>
          </div>
        );
    }
  }
  export default Careers;