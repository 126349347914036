import React from "react";
import "./carousel.scss";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";

import Photo2 from "./../../assets/adam-paclt.jpg";
import Photo from "./../../assets/marek.jpg";

class CarouselPage extends React.Component {
    render() {
        const {Content} = this.props;
        return (
          <div className="home-page-carousel-background">
          <MDBContainer>
            <MDBCarousel
            duration={10000}
            activeItem={1}
            length={2}
            showControls={true}
            showIndicators={true}
            interval={false}
            className="home-page-carousel-wrap from-right"
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                  <div className="home-page-carousel">
                      <MDBCarouselCaption className="carousel-texts">
                          <p className="text-small">{Content[0].about}</p>
                          <p className="text-small">{Content[0].name}</p>
                          <small>{Content[0].job}</small>
                      </MDBCarouselCaption>
                      <MDBView className="carousel-img">
                          <img src={Photo} alt=""/>
                      <MDBMask overlay="black-light" />
                      </MDBView>
                      <div className="carousel-img-after"><div className="carousel-logo-small"></div></div>
                  </div>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                  <div className="home-page-carousel">
                      
                      <MDBCarouselCaption className="carousel-texts">
                          <p className="text-small">{Content[1].about}</p>
                          <p className="text-small">{Content[1].name}</p>
                          <small>{Content[1].job}</small>
                      </MDBCarouselCaption>
                      <MDBView className="carousel-img">
                          <img src={Photo2} alt=""/>
                      <MDBMask overlay="black-light" />
                      </MDBView>
                      <div className="carousel-img-after"><div className="carousel-logo-small2"></div></div>
                  </div>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
          </MDBContainer>
          </div>
        );
    }
}

export default CarouselPage;